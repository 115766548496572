import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../components/styles"
import * as util from "../../templates/util"
import "./bootstrap_modal_fix.css"
import { StageContext } from "../../contexts/stage"
import * as Buttons from "../../components/buttons"
import * as constants from "../../components/stages/constants"
import * as text from "../../constants/rewards"
import * as tracking from "../../tracking"
import SEO from "../../components/seo"
import { LocationContext } from "../../contexts/location"
import { Partner } from "./rewards/Partner"
import { RewardsFilter } from "./rewards/RewardsFilter"

const partnerIsOpen = partner => partner.node.currentlyOpen !== false
const partnerIsClosed = partner => !partnerIsOpen(partner)

const ListOpenPartners = props => (
  <ListPartners filterFunction={partnerIsOpen} {...props} />
)
const ListClosedPartners = props => (
  <ListPartners filterFunction={partnerIsClosed} {...props} />
)

const ListPartners = ({ partners, filterFunction }) => {
  return partners
    .filter(filterFunction)
    .map((partner, idx) => (
      <Partner
        key={partner.node.id}
        idx={partner.node.id}
        {...partner.node}
        rewards={partner.rewards}
      />
    ))
}

export const Rewards = ({ partners, categories }) => {
  const [filter, setFilter] = React.useState("All")

  const partnersFilteredByCategory = util.filterPartnersByCategory(
    partners,
    filter
  )

  const stageContext = React.useContext(StageContext)
  const locationContext = React.useContext(LocationContext)

  React.useEffect(() => {
    tracking.trackCategoryFilter(filter)
  }, [filter])

  return (
    <Theme.RewardsPage className="container-fluid">
      <SEO
        title={`Spend Partners in ${util.capitalizeFirstLetter(
          locationContext.location
        )}`}
      />
      <Buttons.BackButton
        back={() => stageContext.setStage(constants.SELECT_INTEREST)}
      />
      <div className="row">
        <Theme.PageHeader className="col-12">
          {text.SPEND_PARTNER_HEADER}
        </Theme.PageHeader>
        <Theme.PageSubHeader className="col-12">
          {text.SPEND_PARTNER_SUBHEADER}
        </Theme.PageSubHeader>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <RewardsFilter
            setFilter={setFilter}
            filter={filter}
            partners={partners}
            categories={categories}
          />
        </div>
        <Theme.SpendPartnerList
          className="col-lg-8 col-sm-12 card-group mx-auto"
          role="list"
        >
          <ListOpenPartners partners={partnersFilteredByCategory} />
          <ListClosedPartners partners={partnersFilteredByCategory} />
          <NoPartners partners={partnersFilteredByCategory} />
        </Theme.SpendPartnerList>
      </div>
    </Theme.RewardsPage>
  )
}

Rewards.propTypes = {
  categories: PropTypes.any,
  partners: PropTypes.any,
  rewards: PropTypes.any,
  serviceID: PropTypes.any,
}

export const DEFAULT_CATEGORIES = [
  {
    name: "All",
  },
]

const Reward = props => {
  return (
    <Theme.RewardContainer className="col-lg-4 col-sm-12" idx={props.idx}>
      <Theme.PointsContainer>
        <Theme.PointsDigits>{props.pointsRequired}</Theme.PointsDigits>
        <Theme.PointsWords>POINTS</Theme.PointsWords>
      </Theme.PointsContainer>
      <Theme.RewardDescription>{props.description}</Theme.RewardDescription>
    </Theme.RewardContainer>
  )
}

Reward.propTypes = {
  description: PropTypes.string,
  idx: PropTypes.number,
  pointsRequired: PropTypes.bool,
}

const NoPartners = ({ partners }) => {
  if (partners.length > 0) return null

  return (
    <Theme.NoPartnersText>
      {text.CATEGORY_WITHOUT_PARTNERS_TEXT}
    </Theme.NoPartnersText>
  )
}
