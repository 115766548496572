import React from 'react'
import * as Theme from '../../../styles'

export const ClosedNotice = ( { closed } ) => {

    if (!closed)
      return null;
  
    return (<Theme.ClosedNotice>This spending opportunity is currently paused due to COVID-19 restrictions.</Theme.ClosedNotice>)
  
  }
  