/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import * as Theme from "../components/styles"
import "bootstrap/dist/css/bootstrap.css"
import "./body.css"
import { ChangeLocationHeader } from "./changeLocationHeader"
import { LocationContextWrapper } from "../contexts/location"
import { StageContextWrapper } from "../contexts/stage"
import { Helmet } from "react-helmet"
import { PageFooter } from "../components/service/footer"
import { LoadingIcon } from "./LoadingIcon"
import { PrivacyControl } from "../components/PrivacyControl"
import "./static.css"

const StaticLayout = ({ children, data }) => {
  return (
    <Theme.PageContainer className="container-fluid" role="main">
      <Helmet>
        <title>Capital Card Reward Finder</title>
      </Helmet>
      {children}
    </Theme.PageContainer>
  )
}

const RegularLayout = ({ children, data }) => {
  return (
    <StageContextWrapper>
      <LocationContextWrapper>
        <Theme.AppContainer className="container-fluid" role="main">
          <Helmet>
            <title>Capital Card Reward Finder</title>
          </Helmet>
          <ChangeLocationHeader />
          <Theme.BackgroundLogoContainer
            className="d-inline d-md-none"
            width={300}
          >
            <Theme.BackgroundLogo
              fluid={data.logo.childImageSharp.fluid}
              style={{ width: "100%" }}
              loading={"eager"}
            />
          </Theme.BackgroundLogoContainer>
          <Theme.BackgroundLogoContainer
            className="d-none d-md-inline"
            width={460}
          >
            <Theme.BackgroundLogo
              fluid={data.logo.childImageSharp.fluid}
              style={{ width: "100%" }}
              loading={"eager"}
            />
          </Theme.BackgroundLogoContainer>
          {children}
          <LoadingIcon />
          <PrivacyControl />
          <PageFooter />
        </Theme.AppContainer>
      </LocationContextWrapper>
    </StageContextWrapper>
  )
}

RegularLayout.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    bg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.any,
        }),
      }),
    }),
  }),
}

const PrintLayout = ({ children }) => {
  return <code>{children}</code>
}

PrintLayout.propTypes = {
  children: PropTypes.any,
}

const Layout = ({ children, pageContext }) => {
  const { layout } = pageContext

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      bg: file(relativePath: { eq: "bg.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      logo: file(relativePath: { eq: "logo_optimised2.png" }) {
        childImageSharp {
          fixed(width: 460, height: 222) {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  switch (layout) {
    case "print":
      return <PrintLayout>{children}</PrintLayout>
    case "static":
      return <StaticLayout>{children}</StaticLayout>
    default:
      return <RegularLayout data={data}>{children}</RegularLayout>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.any,
}

export default Layout
