import React, { useEffect } from "react"
import * as Theme from "./styles"
import privacy_control from "../images/privacy_control.png"
import { Link } from "gatsby"
import * as tracking from "../tracking"

const CookieButton = ({ toggle }) => {
  const [enabled, setEnabled] = React.useState(tracking.isTrackingEnabled())

  return (
    <>
      <p>Tracking is currently: <b>{enabled ? "On" : "Off"}</b></p>
      <Theme.CookieButtonContainer className="row">
        <Theme.CookieButton data-control-type="cookie-button-accept" enabled={enabled} onClick={() => { setEnabled(true); tracking.enableTracking(); toggle(); }} type="accept" className="col-12">
          I accept
        </Theme.CookieButton>
        <Theme.CookieButton enabled={enabled} onClick={() => { setEnabled(false); tracking.disableTracking(); toggle(); }} type="deny" className="col-12">
          I do not accept
        </Theme.CookieButton>
      </Theme.CookieButtonContainer>
    </>
  )
}
const PrivacyWindow = ({ isOpen, toggle }) => {
  if (isOpen !== true) return null
  return (
    <Theme.PrivacyWindow>
      <h4>
        Cookie Options <Theme.PrivacyWindowClose onClick={toggle}>X</Theme.PrivacyWindowClose>
      </h4>
      <p>This site uses cookies to store information on your computer.</p>
      <p>
        Learn more about our <Link to="/pages/cookies">Cookies</Link> or read
        our <Link to="/pages/privacy">Privacy Policy</Link>
      </p>
      <CookieButton toggle={toggle} />
    </Theme.PrivacyWindow>
  )
}
export const PrivacyControl = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleOpen = () => setIsOpen(isOpen => !isOpen)

  console.log(tracking.isTrackingEnabled());

  useEffect(() => {

    if (tracking.isFirstVisit() && process.env.PRODUCTION) {
      setIsOpen(true);
      tracking.enableTracking();
    }

  }, []);

  return (
    <>
      <Theme.PrivacyControl src={privacy_control} onClick={toggleOpen} />
      <PrivacyWindow isOpen={isOpen} toggle={toggleOpen} />
    </>
  )
}
