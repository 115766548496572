import PropTypes from "prop-types"
import * as Theme from "../../components/styles"
import React from "react"
import { PageFooter } from "./footer"
import * as Buttons from "../../components/buttons"
import { StageContext } from "../../contexts/stage"
import * as constants from "../../components/stages/constants"
import { ShowEvents } from "./events/ShowEvents"
import { EventsFilter } from "./events/EventsFilter"
import SEO from "../../components/seo"
import * as text from "../../constants/events"
import { useFilter } from "./events/useFilter"
import * as util from './util'

export const Events = ({ events }) => {
  const filter = useFilter(events)
  const eventsToList = util.sortEvents(filter.filterEvents(events))
  const stageContext = React.useContext(StageContext)

  return (
    <>
      <Theme.EventsListContainer className="container-fluid">
        <SEO title="Upcoming Capital Card Events" />
        <Buttons.BackButton
          back={() => stageContext.setStage(constants.SELECT_INTEREST)}
        />
        <div className="row">
          <Theme.PageHeader className="col-12">
            {text.UPCOMING_EVENTS_TITLE}
          </Theme.PageHeader>
          <Theme.PageSubHeader className="col-12">
            {text.EVENTS_SUBHEADER}
          </Theme.PageSubHeader>
        </div>
        <div className="row">
          <EventsFilter {...filter} />
          <Theme.EventsList className="col-lg-9 col-sm-12">
            <ShowEvents eventsToList={eventsToList} />
          </Theme.EventsList>
        </div>
        <PageFooter />
      </Theme.EventsListContainer>
    </>
  )
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
}
