const setCookiesDisabled = () => setCookie("tracking", "disabled", 3600)
const unsetCookiesDisabled = () => setCookie("tracking", "enabled", 3600)
const isCookieStatusSet = () => getCookie("tracking") === "disabled"

export const isSSR = () => !(typeof window !== "undefined")
export const isAnalyticsDisabled = () => !(window.gtag !== undefined)


function getCookie(name) {
  if (isSSR()) return null;
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)")
  return v ? v[2] : null
}
function setCookie(name, value, days) {
  if (isSSR()) return null;
  var d = new Date()
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString()
}
function deleteCookie(name) {
  setCookie(name, "", -1)
}

export const isFirstVisit = () => (getCookie("tracking") === null)


export const isTrackingEnabled = () => {
  return !isCookieStatusSet() && !isFirstVisit()
  window.localStorage.getItem("trackingDisabled") === true
}

export const disableTracking = () => {
  //  window.localStorage.setItem("trackingDisabled", true)
  setCookiesDisabled()
}

export const enableTracking = () => {
  unsetCookiesDisabled()
}

export const disableTrackingOnPageLoad = () =>
  isTrackingEnabled() !== true &&
  (window["ga-disable-GA_MEASUREMENT_ID"] = true)

export const trackEvent = (eventType, data) => {
  if (isSSR()) return null

  if (!isTrackingEnabled()) return null;
  if (isAnalyticsDisabled()) return null;

  return window.gtag("event", eventType, { ...data })
}

export const trackStageView = stageName => {
  trackEvent(stageName, {
    event_label: stageName,
    event_category: "Menu Stage",
  })
}

export const trackSpendPartnerView = partnerName => {
  trackEvent(partnerName, {
    event_label: partnerName,
    event_category: "Spend Partner",
  })
}

export const setUserLocation = location => {
  if (isSSR()) return null
  if (isAnalyticsDisabled()) { 
    window['ga-disable-UA-177842544-1'] = true
    return null;
  }

  window.gtag("set", {
    dimension1: location,
  })
}

export const setupLocationDimension = () => {
  if (isSSR()) return null
  if (isAnalyticsDisabled()) return null;

  window.gtag("set", "cd1", "Service")
}

const asPageURL = pageName => {
  return pageName.replace(" ", "-") + "/"
}

export const trackPageview = page => {
  if (isSSR()) return null

  if (!isTrackingEnabled()) return null
  if (isAnalyticsDisabled()) { 
    window['ga-disable-UA-177842544-1'] = true
    return null;
  }

  window.gtag("set", "page", asPageURL(page))
  window.gtag("send", "pageview", page)
}

export const trackCategoryFilter = filterName => {
  trackEvent(filterName, {
    event_label: filterName,
    event_category: "Category Filter",
  })
}

export const trackEventFilter = filterName => {
  trackEvent(filterName, {
    event_label: filterName,
    event_category: "Event Filter",
  })
}
