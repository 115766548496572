import React from "react"
import * as util from "../templates/util"
import { navigate } from "gatsby"
import { StageContext } from "../contexts/stage"
import * as Stages from "../components/stages/index"
import { useStaticQuery, graphql } from "gatsby"
import "polyfill-object.fromentries"
import * as tracking from '../tracking'

export const LocationContext = React.createContext()

export const LocationContextWrapper = ({ children }) => {
  const [location, setLocation] = React.useState("")
  const [locationMap, setLocationMap] = React.useState({})
  const [regions, setRegionMap] = React.useState({})

  const stageContext = React.useContext(StageContext)
  const data = useStaticQuery(graphql`
    query HeaderQuery {

      allContentfulRegion {
        edges {
          node {
            id
            name
          }
        }
      }

      allContentfulService {
        edges {
          node {
            name
            slug
            region {
              id
            }
          }
        }
      }
    }
  `)

  const buildServiceMap = edges =>
    Object.fromEntries(edges.map(({ node }) => [node.slug, node.name]))

  const buildServiceRegionMap = edges => 
  Object.fromEntries(edges.map(({ node }) => [node.slug, node.region.id]))

  const buildRegionMap = edges =>
    Object.fromEntries(edges.map(({ node }) => [node.name, node.id]))

  React.useEffect(() => {
    setLocationMap(buildServiceMap(data.allContentfulService.edges))
    setRegionMap({
      regions: buildRegionMap(data.allContentfulRegion.edges),
      serviceRegions: buildServiceRegionMap(data.allContentfulService.edges)
    })
  }, [data])

  const resetLocation = () => {
    util.persistLocation("")
    setLocation("")
    stageContext.setStage(Stages.LOCATION_SELECT)
    navigate("/")
  }

  React.useEffect(() => {
    tracking.setupLocationDimension();
    setLocation(util.getLocation() ? util.getLocation() : "")
  }, [])

  React.useEffect(() => {
    if (location !== "") {
      util.persistLocation(location);
      tracking.setUserLocation(location);
    }
  }, [location])


  return (
    <LocationContext.Provider
      value={{
        location: location,
        setLocation: setLocation,
        resetLocation: resetLocation,
        locationMap: locationMap,
        setLocationMap: setLocationMap,
        regions: regions
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
