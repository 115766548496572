import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as Theme from "../../../styles";
import { SpendPartnerHeader } from "./SpendPartnerHeader";
import { SpendPartnerLink } from "./SpendPartnerLink";
import { ClosedNotice } from './ClosedNotice'

const partnerTextContent = (description) => description !== undefined ? documentToReactComponents(JSON.parse(description.raw)) : null;

export const SmallerModalContent = props => (
  <div className="d-block d-lg-none">
    <div className="row">
      <SpendPartnerHeader picture={props.picture} />
    </div>
    <div className="row">
      <Theme.SpendPartnerContent className="col-lg-12 col-sm-12" tabIndex={0}>
      <ClosedNotice closed={!props.currentlyOpen} />
        {partnerTextContent(props.description)}
        {props.websiteUrl && <SpendPartnerLink url={props.websiteUrl} />}
      </Theme.SpendPartnerContent>
    </div>
  </div>
);
