import React from "react"
import * as Theme from "./styles"
import { navigate } from "@reach/router"
import PropTypes from 'prop-types'

export const BackButton = ({ back }) => {
  const goBack = () => {
    back()
  }

  return (
    <div className="row">
      <Theme.BackButton
        className="col-sm-12 col-lg-3"
        data-control-type={"back-button"}
        onClick={() => goBack()}
      >
        Back
      </Theme.BackButton>
    </div>
  )
}

BackButton.propTypes = {
  back: PropTypes.func.isRequired
}
