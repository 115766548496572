import dayjs from "dayjs"
import {
  FILTER_IN_NEXT_WEEK,
  FILTER_IN_NEXT_MONTH,
  FILTER_IN_NEXT_SIX_MONTHS,
} from "../components/service/index"
import * as INTRO_STAGE from "../components/stages/constants"

export const isSSR = () =>
  !(typeof window !== "undefined" && window.localStorage)

export const persistLocation = newLocation =>
  localStorage.setItem("location", newLocation)

export const getLocation = () =>
  isSSR() ? null : localStorage.getItem("location")

export const userHasSelectedLocation = () =>
  getLocation() !== undefined && getLocation() !== null && getLocation() !== ""

export const whereToStartFrom = () =>
  userHasSelectedLocation()
    ? INTRO_STAGE.SELECT_INTEREST
    : userHasSeenIntro()
    ? INTRO_STAGE.START_STAGE
    : INTRO_STAGE.INTRO_STAGE

export const getUserHasSeenIntro = () =>
  true || localStorage.getItem("seenIntro")

export const userHasSeenIntro = () => getUserHasSeenIntro() === true

export const setUserHasSeenIntro = () => localStorage.setItem("seenIntro", true)

export const unsetUserHasSeenIntro = () =>
  localStorage.setItem("seenIntro", false)

export const eventFilters = filter => {
  switch (filter) {
    case FILTER_IN_NEXT_WEEK:
      return event => {
        return dayjs(event.node.when).diff(dayjs(Date.now()), "days") < 7
      }
    case FILTER_IN_NEXT_MONTH:
      return event => {
        return dayjs(event.node.when).diff(dayjs(Date.now()), "days") < 30
      }
    case FILTER_IN_NEXT_SIX_MONTHS:
      return event =>
        dayjs(event.node.when).diff(dayjs(Date.now()), "days") < 180
      break
  }
}

export const countForCategory = (partners, category) => {
  if (category == "All") return partners.length

  return partners.filter(partner => {
    return partner.node.categories.map(({ name }) => name).includes(category)
  }).length
}

export const filterRewardsByServiceID = (serviceID, rewards) => {
  if (rewards === null) return []

  return rewards.filter(reward =>
    reward.service.map(({ id }) => id).includes(serviceID)
  )
}

export const filterPartnersByCategory = (partners, filter) => {
  if (filter == "All") return partners

  return partners.filter(partner => {
    return partner.node.categories.map(({ name }) => name).includes(filter)
  })
}

export const colorCodeDays = days => {
  let days_pct = Math.round((days / 30) * 10) * 10
  return days_pct > 90 ? days_pct : 90
}

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const sortRewardsBySpendPartner = (groupedRewards, reward) => {
  reward.spendPartner.forEach(partner => {
    if (
      groupedRewards[partner.id] === undefined ||
      groupedRewards[partner.id] === null
    ) {
      groupedRewards[partner.id] = [reward]
    } else {
      groupedRewards[partner.id].push(reward)
    }
  })

  return groupedRewards
}

export const associateSpendPartnersWithRewards = (groupedRewards, partners) => {
  return partners
    .map(partner => {
      return {
        ...partner,
        rewards: groupedRewards[partner.node.id] || [],
      }
    })
    .filter(partner => {
      return partner.rewards.length > 0
    })
}
