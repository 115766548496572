import { desaturate } from 'polished' 

export const wdp_purple = desaturate(0.0, "#6a4372");
export const wdp_green = "#7daa51";
export const wdp_yellow = "#D2AC31";
export const wdp_green2 = desaturate(0.00, "#2E6B68");
export const wdp_shell = "#A79089";
export const wdp_orange = "#B9523E";
export const wdp_cherry = "#86193E";

export const wdp_green_1 = '#78BB36';
export const wdp_purple_1 = '#491C55';
export const wdp_purple_2 = '#6F5176';
export const wdp_purple_3 = '#7B4F7E';
export const wdp_purple_4 = '#A487A6';
export const wdp_purple_5 = '#E6DEE8';
export const wdp_black_1 = desaturate(0.2, '#000')
export const wdp_black_2 = desaturate(0.3, '#000');