import React from "react"
import * as Theme from "../styles"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { StageContext } from '../../contexts/stage'
import * as Stages from '../stages/index'

export const PageFooter = props => {

  const stageContext = React.useContext(StageContext);
  const shouldDisplayFooterText = (stage) => Stages.isStepWithFooter(stage)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "capital-card-symbol.png" }) {
        childImageSharp {
          fixed(width: 16) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mini_logo: file(relativePath: { eq: "wdp_mini_logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Theme.RewardsFooter
      className="navbar fixed-bottom d-block"
      role="contentinfo"
    >
      <Theme.FooterContainer>
          <a href="https://www.contentful.com/" rel="nofollow" target="_blank"><img src="https://images.ctfassets.net/fo9twyrwpveg/7Htleo27dKYua8gio8UEUy/0797152a2d2f8e41db49ecbf1ccffdaa/PoweredByContentful_DarkBackground_MonochromeLogo.svg" style={{"max-width": "100px", "width": "100%", "padding": "0.2rem"}} alt="Powered by Contentful" /></a>
          <Theme.FooterText>
          </Theme.FooterText>
        <Theme.WDPLogo
          fluid={data.mini_logo.childImageSharp.fluid}
          className="d-md-inline"
        />
      </Theme.FooterContainer>
    </Theme.RewardsFooter>
  )
}
