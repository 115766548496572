import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../components/styles"
import { Stage } from "./stage"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

export const StartStage = ({ nextStage }) => {
  const data = useStaticQuery(graphql`
    query {
      start: file(relativePath: { eq: "logo_rf.png" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <Stage
      name="start"
      greeting={"Welcome!"}
      animationProps={{ initial: { scale: 0.5 }, animate: { scale: 1 } }}
    >
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <Theme.Paragraph>
            You&apos;ve worked hard to earn your points, now find places to
            spend them!
          </Theme.Paragraph>
          <Theme.Paragraph>
            This website is designed to help you find Capital Card rewards in
            your area.
          </Theme.Paragraph>
        </div>
        <div className="col-12">
          <Theme.NavButton
            data-control-type={"start-button"}
            onClick={nextStage}
            aria-label="Next Step"
          >
            Start
          </Theme.NavButton>
        </div>
      </div>
    </Stage>
  )
}

StartStage.propTypes = {
  nextStage: PropTypes.any,
}
