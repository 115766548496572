import styled, { keyframes } from "styled-components"
import * as ColourScheme from "./colors"
import locator_white from "../images/locate_transparent.png"
import { desaturate, darken, lighten } from "polished"
import capital_card_symbol from "../images/symbol.png"
import calendar from "../images/calendar.png"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import loading from "../images/loading_icon.gif"
import { motion } from "framer-motion"

const LIGHT_PURPLE = "#dfd3e0"

const colourBoxes = [
  ColourScheme.wdp_green2,
  ColourScheme.wdp_cherry,
  ColourScheme.wdp_orange,
  ColourScheme.wdp_green,
  ColourScheme.wdp_yellow,
  ColourScheme.wdp_purple,
]

const CookieButtonColors = {
  enabled: '#2d884d',
  disabled: '#b34045'
}

const CookieButtonShadows = {
  enabled: darken(0.1, CookieButtonColors.enabled),
  disabled: darken(0.1, CookieButtonColors.disabled)
}

const whiteBoxShadow = `
  box-shadow: ${darken(0.3, ColourScheme.wdp_green)} 4px 4px 2px;
`

const purpleShadow = `
  box-shadow: ${ColourScheme.wdp_purple_5} 3px 3px 1px 1px;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const PartnerLogo = styled.img`
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 15px;
`

export const PartnerLogoSquare = styled.div`
  background-color: #fff;
  padding: 1rem;
  text-align: center;
  picture, img {
    opacity: ${props => props.isOpen ? 1 : 0.5};
  }
`

export const TickIcon = styled.img`
  max-width: 16px;
  margin: 0.1rem;
  margin-right: 1rem;
`

export const PartnerName = styled.h1`
  background-color: ${props => colourBoxes[props.idx]};
  border: 0;
  border-bottom-width: 4px;
  border-color: #fff;
  border-style: solid;
  color: #fff;
  font-size: 1.3em;
  font-weight: 900;
  margin: 0;
  padding: 1.5rem;
  text-shadow: #000 2px 2px;
`

export const RewardsFooter = styled.footer`
  background-color: ${ColourScheme.wdp_purple};
  border-color: #eee;
  border-style: solid;
  border-width: 0px 0 0 0;
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
`

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0em;
  min-height: 10vh;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const RewardsPage = styled.section`
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 70vh;
  color: #fff;
/*  border-top-width: 15px;
  border-style: solid;
  border-color: ${ColourScheme.wdp_green2};
  z-index: 2; */
  background-color: #fff;
  position: relative;
  top: 0;
  left: 0;
`

export const ChangeLocationButton = styled.button`
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  text-shadow: ${darken(0.1, ColourScheme.wdp_green)} 2px 2px;
  padding: 0.2em;
  color: #fff;
  font-weight: 700;
  margin-left: 1em;
`

export const CategoryPartnerCount = styled.span`
  background-color: ${props => (props.active ? LIGHT_PURPLE : "#fff")};
  color: #000;
  width: 48px;
  border-width: ${props => (props.active ? "0px" : "2px")};
  border-color: ${ColourScheme.wdp_purple};
  border-style: solid;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 3;
`

export const hover = keyframes`
    0 { 
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, -16px);
    }

    100% { 
      transform: translate(0, 0);
    }

`

export const LocationLogo = styled.span`
  max-width: 64px;
  animation: ${hover} 3s linear infinite;
`

export const LocationLogoImage = styled.img`
  max-width: 48px;
`

export const PartnerDescriptionRow = styled.div`
  overflow: hidden;
  max-height: 10rem;
  padding: 1rem;
`

export const PartnerModalBody = styled.div`
  padding: 0rem;
  font-size: 1.2rem;
`

export const SpendPartnerContent = styled.section`
  padding: 1rem;
  margin: 1rem;
  max-width: 95%;
`

export const SpendPartnerHeader = styled.div`
  max-height: 600px;
  overflow-y: hidden;
`

export const ModalContent = styled.div`
  padding: 1rem;
`

export const RewardsContainer = styled.table`
  /* purgecss ignore current */
/*  background-color: ${props => desaturate(0.2, colourBoxes[props.idx])}; */
  border: 0;
/*  border-top-width: 25px;
  border-color: #ddd;
  border-style: solid;*/
  padding: 0rem;
  margin-top: 0;
  margin-bottom: 2rem;
  max-width: 90%;
  ${purpleShadow};
`

export const PointsRow = styled.tr`
  min-height: 4rem;
  font-size: 1.6rem;
  padding: 0.2rem;
`

export const PointsRequired = styled.td`
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`

export const RewardDescription = styled.td`
  background-color: rgba(255, 255, 255, 0.05);
  padding-left: 0.5rem;
`

export const PointsDescription = styled.td`
  text-align: left;
  color: ${ColourScheme.wdp_purple_1};
  font-weight: 1.5rem;
  margin: 0;

  strong {
    font-size: 1.2rem;
    color: #777;
  }
`

export const FilterName = styled.h1`
  font-size: 1.5rem;
  text-shadow: #000 2px 2px;
  border-color: #fff;
  padding: 1rem;
  background-color: ${ColourScheme.wdp_purple};
  ${whiteBoxShadow};
`

export const RewardsListHeader = styled.thead`
  background-color: ${ColourScheme.wdp_purple_5};
  color: #000;
  padding: 0.5rem;
  margin: 0;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const filterButtonStyling = props => `
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0em;
  text-align: center;
  padding: 0rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75em;
  position: relative;
  margin-bottom: 0.25em;
  opacity: 1;
  width: 100%;
  border: 0;
`

export const RewardCategory = styled.button`
  background-color: ${props =>
    props.active ? LIGHT_PURPLE : ColourScheme.wdp_purple};
  color: ${props => (props.active ? ColourScheme.wdp_purple : "#fff")};
  ${filterButtonStyling}
`

export const RewardCategories = styled.nav`
  margin-bottom: 1rem;
`

export const RewardsHeaderLogo = styled.img`
  margin: 1rem;
  margin-top: 2rem;
  padding: 1rem;
`

export const PartnerContainer = styled.div`
  font-size: 1.3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0rem;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 1);
  ${whiteBoxShadow};
  color: #000;
`

export const GenericBackground = styled.div``

export const RewardContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: rgba(255, 255, 255, ${props =>
    props.idx % 2 == 0 ? "0.08" : "0.05"}); */
  padding: 1rem;
`

export const PointsWords = styled.div`
  font-size: 1.3rem;
  font-weight: light;
  /* color: rgba(255, 255, 255, 0.5); */
  padding-bottom: 1rem;
`

export const PointsDigits = styled.div`
  padding-top: 1rem;
`

export const PartnerDescription = styled.div`
  padding: 1rem;
  background-color: ${props => props.isOpen ? lighten(0.05, ColourScheme.wdp_purple) : desaturate(0.2, lighten(0.05, ColourScheme.wdp_purple))};
  color: #fff;
  /* background-color: #fcfcfc; */
`

export const PointsContainer = styled.div`
  border-radius: 50%;
  border-color: #fff;
/*  background-color: ${lighten(0.1, ColourScheme.wdp_purple)}; */
  color: #fff;
  border-style: solid;
  border-width: 5px;
  font-size: 4rem;
  font-weight: bold;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/*  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(${capital_card_symbol});
  background-repeat: no-repeat;
  background-position: center;*/
`

export const Header = styled(motion.div)`
  background-color: ${desaturate(0.0, ColourScheme.wdp_green)};
  border-bottom-color: #eee;
  border-style: solid;
  border-width: 0px 0 0px 0;
  height: 10vh;
  z-index: 4;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const LocatorLogo = styled.div`
  background: url(${locator_white});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 1rem;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  strong {
    display: block;
    font-size: 1.8rem;
  }
`

export const NoPartnersText = styled.h1`
  margin: 2rem;
  text-align: center;
  font-weight: 900;
  padding: 4rem;
  background-color: ${ColourScheme.wdp_purple};
`

export const Location = styled.div`
  text-align: right;
  padding: 0.5em;
  display: inline;
  font-size: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
  text-shadow: ${darken(0.1, ColourScheme.wdp_green)} 2px 2px;
  color: #fff;
`

export const PageMessage = styled.div`
  padding: 1rem;
  margin: 1rem;
  background-color: ${ColourScheme.wdp_purple};
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
`

const introduce = keyframes`
/*  from {
    background: 'none';
    background-color: #000;
  }

  to {
    background: radial-gradient(${desaturate(
      0.2,
      ColourScheme.wdp_green2
    )}, ${darken(0.05, desaturate(0.2, ColourScheme.wdp_green2))});
    background-color: ${ColourScheme.wdp_green2};
  }*/
`

export const AppContainer = styled.div`
  /* purgecss ignore current */
  animation: ${introduce} 1s linear forwards;
  background-position: center;
  background-size: cover;
  border: 0;
  color: #000;
  min-height: 100vh;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Lato", Arial;
  font-display: swap;
  min-width: 100%;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 10vh;
  height: 100%;
  padding-bottom: 14vh;
`

export const LogoImage = styled.img`
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  max-width: 8vw;
  opacity: 1;
`

export const AppMenu = styled.div``

export const Greeting = styled.h1`
  color: #fff;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 1.8rem;
`

export const EventInfoButton = styled.button`
  background-color: ${ColourScheme.wdp_green};
  padding: 0.5em;
  font-weight: 900;
  font-size: 1.25rem;
  color: #fff;
  border: 0;
  float: right;
  margin: 1rem;
`

export const DaysLeftText = styled.div``

export const DaysLeftNumber = styled.strong``

export const MoreInformationContainer = styled.div`
  text-align: right;
`

export const MoreInfoRow = styled.div`
  padding: 0.5em;
`

export const GreetingContainer = styled.div`
  margin: 0;
  padding: 0.75rem;
  display: flex;
  background-color: ${ColourScheme.wdp_purple_3};
  justify-content: space-between;
  align-items: center;
  border: 0;
`

export const AlignmentMarker = styled.div`
  width: 15%;
`
export const Button = styled.button`
  background-color: ${lighten(0.1, ColourScheme.wdp_purple)};
  color: #fff;
  border-color: ${ColourScheme.wdp_purple};
  border-width: 0px;
  border-style: solid;
  ${purpleShadow}
  padding: 1rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  border-radius: 10px;
  text-shadow: ${darken(0.1, ColourScheme.wdp_purple)} 2px 2px;
`

export const CloseButton = styled(Button)`
  box-shadow: none;
  background-color: #fff;
  color: #000;
  border-width: 2px;
  border-color: ${ColourScheme.wdp_purple};
  text-shadow: none;
  font-weight: normal;
`

export const NavButton = styled(Button)`
  margin: 1rem;
  background-color: ${props =>
    props.disabled ? "grey" : lighten(0.1, ColourScheme.wdp_purple)};
`

export const EventsList = styled.section`
  margin-top: 2em;
  padding: 0;
`

export const EventsListContainer = styled.div`
  min-height: 100vh;
  background-color: #fff;
  margin-bottom: 3rem;
`

export const EventsFilter = styled.div``

export const PartnerCardFooter = styled.div`
  background-color: ${ColourScheme.wdp_green2};
  min-height: 2.5em;
`

export const PartnerCategories = styled.ul`
  padding: 0;
  margin: 0;
`

export const PartnerCategory = styled.li`
  list-style-type: none;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`

export const FilterButton = styled.button`
  ${filterButtonStyling}
  background-color: ${props =>
    props.active ? LIGHT_PURPLE : ColourScheme.wdp_purple};
  color: ${props => (props.active ? ColourScheme.wdp_purple : "#fff")};
  &:hover {

  }
`

export const EventsFilterHeader = styled.h2`
  color: #000;
  margin: 0.2em;
  border: 0;
`

export const EventItem = styled.article`
  background-color: #fff;
  margin: 1em;
  color: #000;
  ${purpleShadow};
  position: relative;
  max-width: 1280px;
`

export const EventName = styled.h1`
  color: #000;
  font-size: 1.5rem;
  margin: 0rem;
  font-weight: 900;
  display: inline;
`

export const EventDescription = styled.div`
  padding: 1em;
`

export const EventDateUpcoming = styled.div`
  background-color: ${props =>
    desaturate(0.1, IndicatorColor(props.indicator))};
  box-shadow: ${props => darken(0.1, IndicatorColor(props.indicator))} 2px 2px
    2px;
  color: #fff;
  padding: 0em;
  font-size: 1em;
  strong {
    padding: 0.1em;
    font-size: 2rem;
    line-height: 1rem;
    font-weight: 900;
    font-family: "Courier Sans", sans-serif;
  }
`

export const EventDate = styled.div`
  text-align: center;
  margin: 0em;
  padding: 1em;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const RewardCategoryIcon = styled.img`
  max-width: 48px;
  filter: invert(20%) sepia(78%) saturate(3207%) hue-rotate(166deg)
    brightness(89%) contrast(101%);
`

export const Paragraph = styled.p`
  font-size: 1.3rem;
  margin: 1rem;
`

export const IconContainer = styled.div`
  margin: 1rem;
  width: 64px;
  text-align: center;
`

export const StageIcon = styled.img`
  max-height: 100px;
  width: auto;
`

export const StageContainer = styled.section`
  background-color: #fff;
  ${purpleShadow}
  color: #000;
  padding: 0rem;
  margin: 1rem;
  margin-top: 17vh;
  margin-bottom: 1rem;
  border-color: ${ColourScheme.wdp_purple};
  border-style: solid;
  border-width: 2px;
  margin-left: auto;
  margin-right: auto;
  /*  max-width: 460px; */
  z-index: 5;
  border-radius: 10px;
`

export const StageHeader = styled.h2`
  margin: 1rem;
  margin-top: 2rem;
  text-align: center;
  border: 0;
  border-bottom-width: 2px;
  border-color: #fff;
`

export const WDPLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  align-items: center;
`

export const ChoiceContainer = styled.div`
  text-align: center;
  padding: 1rem;
`

export const ModalHeading = styled.h1`
  color: ${ColourScheme.wdp_purple};
`

export const ModalSubHeading = styled.h3``

export const QuestionContainer = styled.div`
  text-align: center;
`

export const ChoiceButton = styled.button`
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  color: #000;
  border-color: ${ColourScheme.wdp_purple};
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem 0rem 0.5rem 0rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  border-radius: 25px;

  &:hover {
    background-color: ${ColourScheme.wdp_purple_5};
    border-color: transparent;
  }
`

export const QuestionText = styled.h2`
  font-size: 1.5em;
  display: block;

  strong {
    font-weight: bold;
  }
`

export const LocationSelectInput = styled.select`
  font-size: 1.25rem;
  padding: 1rem;
  border-radius: 15px;
`

/* export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: fixed;
  margin: auto;
  z-index: 0;
  opacity: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`; */

export const EventPointsContainer = styled.div`
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-direction: column;
  padding: 1rem;
  strong {
    font-size: 4rem;
  }
`

export const EventsPoints = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  color: ${ColourScheme.wdp_green};
`

export const SpendPartnerLogo = styled.span`
  padding: 0rem;
`

export const EventsHeader = styled.div`
  padding: 1rem;
`

export const SpecialCondition = styled.li`
  color: #000;
  width: auto;
  margin: 0.2em;
  list-style-type: none;
  font-weight: bold;
  padding: 0;
  color: #222;
`

export const SpecialConditionsList = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
`

export const SpecialConditionsSelector = styled.ul`
  margin: 0;
  padding: 0;
`

export const SpecialConditionIcon = styled.img`
  width: 32px;
  height: 32px;
`

export const SpendPartnerList = styled.div``

export const SpecialConditionOption = styled(FilterButton)`
  list-style-type: none;
`

export const SpendPartnerTheme = styled.div`
  background-image: url(${props => backgrounds[props.idx % 3]});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 200px;
`

export const TriangleSection = styled.section`
  position: absolute;
  background-color: #548583;
  background-image: url(${calendar});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 100px;
    border-color: #548583 transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  }
`

export const EventMonth = styled.div`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2.5rem;
  padding: 0.5rem;
`

export const EventTime = styled.div`
  text-transform: uppercase;
  font-size: 1em;
`

export const EventDay = styled.div`
  font-size: 5rem;
  line-height: 4rem;
  font-weight: 300;
`

export const EventDayOfWeek = styled.div`
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
`

export const PageHeader = styled.h1`
  color: ${ColourScheme.wdp_purple};
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

export const PageSubHeader = styled.h2`
  color: #777;
  font-weight: normal;
  margin-bottom: 1.5rem;
`

export const EventDateContainer = styled.div`
  padding: 0em;
  background-color: ${desaturate(0.05, lighten(0.2, ColourScheme.wdp_purple))};
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const CategoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
`

export const CategoryItem = styled.li`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem;
  margin: 2rem;
  font-size: 2rem;
  width: 20rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 2px;
`

export const PromoImage = styled.img`
  margin: 0.5rem;
  max-width: 45%;
`

export const PartnerCard = styled.div`
  min-width: 18rem;
  margin: 0.5rem;
  margin-bottom: 1rem;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-color: ${darken(0.1, ColourScheme.wdp_purple)};
  background-color: ${props => props.isOpen ? lighten(0.05, ColourScheme.wdp_purple) : desaturate(0.2, lighten(0.05, ColourScheme.wdp_purple))};
  ${purpleShadow};
  border-style: solid;
  border-width: 1px;
`

export const PartnerCardHeader = styled.div`
  background-color: ${ColourScheme.wdp_purple};
  min-height: 150px;
`

export const PartnerModal = styled.div`
  color: #000;
`

export const ModalHeader = styled.div`
  background-color: ${ColourScheme.wdp_purple_2};
  padding: 1rem;
  color: #fff;
`

export const PartnerShowMoreButton = styled.button`
  font-weight: bold;
  color: ${ColourScheme.wdp_green2};
  border-radius: 15px;
  border-width: 4px;
  border-style: solid;
  border-color: ${ColourScheme.wdp_green2};
  margin: 1em;
  padding: 0.5em;
  width: 15em;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
`

export const PartnerCardTitle = styled.a`
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`

export const BackButton = styled.button`
  font-weight: 700;
  font-size: 1.2em;
  margin: 1em;
  padding: 0.5em;
  background-color: ${ColourScheme.wdp_green};
  box-shadow: ${darken(0.2, ColourScheme.wdp_green)} 2px 2px 0px 0px;
  border-width: 0;
  color: #fff;
  max-width: 200px;

  &:hover {
  }
`

export const DisplayPromoImage = styled.img``

export const PointsRequiredText = styled.div`
  color: ${ColourScheme.wdp_purple_1};
  padding: 0em;
`

export const StageFooter = styled.div`
  background-color: ${ColourScheme.wdp_green2};
`

export const MiniLogo = styled.img`
  max-width: 64px;
  padding: 0.5rem;
`

export const BolderText = styled.div`
  font-weight: 900;
`

export const UnderDevelopmentContainer = styled.div`
  border-radius: 10px;
  padding: 1em;
  margin: 1em;
  font-size: 1.2rem;
  background-color: #eee;
  color: #000;
`

export const HeaderGreen = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  opacity: 0;
`

export const FooterPurple = styled.img`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  opacity: 0;
  z-index: -1;
`

export const WDPLogo = styled(Img)`
  width: 20vh;
`

export const Overlay = styled.div`
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  opacity: 0;
`

export const BackgroundLogoContainer = styled.div`
  position: fixed;
  top: 14vh;
  max-height: 10vh;
  width: ${props => props.width + "px"};
  z-index: -1;
`

export const BackgroundLogo = styled(Img)``

export const SpendPartnerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SpendPartnerHeaderImage = styled(BackgroundImage)`
  height: 25vh;
  max-height: 600px;
  background-position: center;
`

export const SpendPartnerContentLarge = styled.div`
  padding: 2em;
`

export const RewardName = styled.div`
  color: ${ColourScheme.wdp_purple};
`

export const RewardFurtherDescription = styled.div`
  color: #555;
  font-size: 1.1rem;
`

export const FooterText = styled.div`
  font-size: 1.1rem;

  strong {
    display: block;
  }
`

export const SpendPartnerLink = styled.a`
  padding: 0.5rem;
  color: #000;
  text-decoration: underline;
`

export const LoadingIcon = styled.img`
  position: absolute;
  width: 64px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -2;
  margin-top: 8vh;
`
export const MapLoadingIndicator = styled.div`
  background-image: url(${loading});
  background-position: center;
  background-repeat: no-repeat;
`

export const PrivacyControl = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const PrivacyWindow = styled.div`
  z-index: 6;
  background-color: #000;
  bottom: 8px;
  left: 8px;
  position: fixed;
  color: #fff;
  padding: 1rem;
  max-width: 400px;
  border-radius: 15px;

  a, a:hover, a:visited {
    color: ${ColourScheme.wdp_green};
    font-weight: 600;
  }

  h4 {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
`;

export const CookieButton = styled.div`
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  background-color: ${props => props.type == "accept" ? CookieButtonColors.enabled : CookieButtonColors.disabled};
  box-shadow: ${props => props.type == "accept" ? `1px 1px 0px 2px ${CookieButtonShadows.enabled}` : `inset 1px 1px 0px 2px ${CookieButtonShadows.disabled}`}
`;

export const PageContainer = styled.div`
  padding: 1rem;
  font-family: "Lato";
  max-width: 1024px;

  p {
    font-size: 1.5rem;
  }

  h1, h2, h3 {
    color: ${ColourScheme.wdp_purple};
    font-weight: 900;
  }

  blockquote {
    font-size: 1.7rem;
    background-color: #efefef;
    white-space: pre;
    padding: 1rem;
  }
`;

export const ClosedNotice = styled.div`
  background-color: ${ColourScheme.wdp_purple_5};
  margin: 0rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
`;

export const PrivacyWindowClose = styled.a`
  cursor: pointer;
  font-weight: 900;
  border-width: 1px;
  color: #fff;

  &:hover, &:visited {
    color: #fff;
  }
`;

export const MeetingDetails = styled.div`
  white-space: pre-wrap;
`;

export const RegionSelect = styled.div`
  opacity: ${props => props.isRegionSet ? 0.6 : 1};
`;

export const Divider = styled.div`
  height: 2rem;
`

export const CookieButtonContainer = styled.div`
  margin: auto; 
  text-align: center;
`;