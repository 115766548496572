import dayjs from "dayjs";

export class EventDateFormatter {
  constructor(date) {
    this.date = date;
  }

  day_of_week_short_string() {
    return dayjs(this.date).format("ddd");
  }

  day_of_month_numerical() {
    return dayjs(this.date).format("DD");
  }

  month_short_string() {
    return dayjs(this.date).format("MMM");
  }

  days_left_numerical() {
    return dayjs(this.date).diff(dayjs(Date.now()), "days");
  }

  time_of_day_with_am_or_pm() {
    return dayjs(this.date).format("HH:mma");
  }
}
