import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../styles"
import * as Modal from "./index"
import { Address } from "./partner/Address"
import { Map } from "./event/Map"
import { Link } from "gatsby"

const MeetingDetails = ({ meetingDetails }) => {
  if (
    meetingDetails &&
    meetingDetails.childMarkdownRemark &&
    meetingDetails.childMarkdownRemark.html
  ) {
    return (
      <Theme.MeetingDetails
        dangerouslySetInnerHTML={{
          __html: meetingDetails.childMarkdownRemark.html,
        }}
      ></Theme.MeetingDetails>
    )
  } else {
    return (
      <Theme.MeetingDetails>
        No details could be found. Please report this error
      </Theme.MeetingDetails>
    )
  }
}

const MeetingLink = ({ eventUrl }) => {
  eventUrl ? (
    <>
      <Theme.ModalSubHeading>Link</Theme.ModalSubHeading>
      <p>Please speak to your key worker for more information</p>
    </>
  ) : (
    <>
      <Theme.ModalSubHeading>Link</Theme.ModalSubHeading>
      <Link to={eventUrl}>{eventUrl}</Link>
    </>
  )
}

const OnlineEventModal = props => {
  return (
    <Modal.Modal {...props} name={"Event Details"}>
      <Theme.ModalContent className="row">
        <div className="col-lg-6 col-sm-12">
          <Theme.ModalHeading>How it works</Theme.ModalHeading>
          <Theme.ModalSubHeading>Details</Theme.ModalSubHeading>
          <MeetingDetails meetingDetails={props.meetingDetails} />
          <MeetingLink eventUrl={props.eventUrl} />
        </div>
        <div className="col-lg-6 col-sm-12">
          <Theme.ModalHeading>What you'll need</Theme.ModalHeading>
          <ul>
            <li>Headphones and a webcam</li>
            <li>A stable internet connection</li>
          </ul>
        </div>
      </Theme.ModalContent>
    </Modal.Modal>
  )
}

const OfflineEventModal = props => {
  return (
    <Modal.Modal {...props} name={"Event Details"}>
      <Theme.ModalContent className="row">
        <div className="col-lg-6 col-sm-12">
          <Theme.ModalHeading>Where to meet</Theme.ModalHeading>
          <Theme.ModalSubHeading>Details</Theme.ModalSubHeading>
          <MeetingDetails meetingDetails={props.meetingDetails} />
          <Theme.ModalSubHeading>Address</Theme.ModalSubHeading>
          <Address address={props.meetingAddress} />
          <Map {...props.meetingLocation} />
        </div>
        <div className="col-lg-6 col-sm-12">
          <Theme.ModalHeading>Event Location</Theme.ModalHeading>
          <Theme.ModalSubHeading>Address</Theme.ModalSubHeading>
          <Address address={props.eventAddress} />
          <Map {...props.actualLocation} />
        </div>
      </Theme.ModalContent>
    </Modal.Modal>
  )
}

export const EventModal = props => {
  return props.isOnline === true ? (
    <OnlineEventModal {...props} />
  ) : (
    <OfflineEventModal {...props} />
  )
}

EventModal.propTypes = {
  actualLocation: PropTypes.any,
  eventAddress: PropTypes.any,
  meetingAddress: PropTypes.any,
  meetingDetails: PropTypes.any,
  meetingLocation: PropTypes.any,
}
