import * as Theme from "../../../components/styles"
import React from "react"
import { EventModal } from "../modals/EventModal"
import { EventsPoints } from "./EventsPoints"
import { SpecialConditions } from "./SpecialConditions"
import { SpendPartner } from "./SpendPartner"
import { EventDateFormatter } from "./EventDateFormatter"

const isOnlineEvent = props =>
  props.specialConditions !== null && props.specialConditions.includes("Online")

export const Event = props => {
  const dateFormatter = new EventDateFormatter(props.when)

  return (
    <Theme.EventItem className="row" data-item-type="event" tabIndex={0}>
      <Theme.EventDateContainer className="col-lg-2 col-4">
        <Theme.EventDate data-event-date={props.when} tabIndex={0}>
          <Theme.EventDayOfWeek>
            {dateFormatter.day_of_week_short_string()}
          </Theme.EventDayOfWeek>
          <Theme.EventDay>
            {dateFormatter.day_of_month_numerical()}
          </Theme.EventDay>
          <Theme.EventMonth>
            {dateFormatter.month_short_string()}
          </Theme.EventMonth>
        </Theme.EventDate>
      </Theme.EventDateContainer>
      <Theme.EventDescription className="col-8 col-xl-8" tabIndex={0}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Theme.EventName title="Event Name">
              {props.description}
            </Theme.EventName>
          </div>
          <div></div>
        </div>
        <div>
          <Theme.EventTime>
            Starts at {dateFormatter.time_of_day_with_am_or_pm()}
          </Theme.EventTime>
        </div>
        <SpecialConditions specialConditions={props.specialConditions} />
        <EventsPoints {...props} />
        <Theme.DaysLeftText>
          <Theme.DaysLeftNumber data-content-type="event-days-remaining">
            {dateFormatter.days_left_numerical()}
          </Theme.DaysLeftNumber>
          &nbsp;days left
        </Theme.DaysLeftText>
        <Theme.EventInfoButton
          data-toggle="modal"
          data-target={`#modal-${props.idx}`}
          data-control-type="event-location-modal-button"
          role="navigation"
          className="d-block d-xl-none"
        >
          More info
        </Theme.EventInfoButton>
      </Theme.EventDescription>
      <div className="col-0 col-xl-2 d-none d-xl-flex flex-column text-align-center justify-content-center align-items-center">
        <Theme.SpendPartnerImage className="d-none d-xl-block">
          <SpendPartner {...props.spendPartner} />
        </Theme.SpendPartnerImage>
        <Theme.EventInfoButton
          data-toggle="modal"
          data-target={`#modal-${props.idx}`}
          data-control-type="event-location-modal-button"
          role="navigation"
          className="d-none d-xl-block"
        >
          More info
        </Theme.EventInfoButton>
      </div>
      <EventModal {...props} isOnline={isOnlineEvent(props)} />
    </Theme.EventItem>
  )
}
