import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../styles"
import { LocationLogo } from "./locationLogo"
import wdp_mini_logo from "../../images/wdp_mini_logo.png"
import Img from "gatsby-image"
import { useStaticQuery } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { graphql } from 'gatsby'

export const Stage = ({ children, name, greeting, animationProps={} }) => {
  return (
    <motion.div
      className="container"
      key={name}
      initial={{ y: 0, ...animationProps.initial }}
      animate={{ y: -7, ...animationProps.animate }}
      exit={{ y: 0, ...animationProps.exit }}
    >
      <div className="row justify-content-center">
        <Theme.StageContainer
          className="col-11 col-lg-6"
          data-menu-item={name}
          data-item-type={"current-stage"}
        >
          <Theme.GreetingContainer className="col-12">
            <Theme.Greeting>{greeting}</Theme.Greeting>
          </Theme.GreetingContainer>

          {children}
        </Theme.StageContainer>
      </div>
    </motion.div>
  )
}

Stage.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
}
