import PropTypes from "prop-types";
import React from "react";
import * as Theme from "../../../styles";


export const Map = ({ lon, lat }) => {
  return (
    <Theme.MapLoadingIndicator>
      <iframe
        loading="lazy"
        width="100%"
        height="170"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src={`https://maps.google.com/maps?q=${lat},${lon}&hl=gb&z=14&output=embed`}
      ></iframe>
    </Theme.MapLoadingIndicator>
  );
};
Map.propTypes = {
  lat: PropTypes.any,
  lon: PropTypes.any,
};
