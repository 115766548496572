import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../../components/styles"
import * as util from "../../../templates/util"
import { DEFAULT_CATEGORIES } from "../rewards"

export const CategorySelectedIndicator = ({ isActive }) => {
  if (!isActive) return null

  return <span>&nbsp;▶&nbsp;</span>
}

CategorySelectedIndicator.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export const RewardsFilter = ({ partners, filter, setFilter, categories }) => {
  return (
    <Theme.RewardCategories aria-labelledby="category-filter" role="search">
      <h2 id="category-filter">Category</h2>
      {DEFAULT_CATEGORIES.concat(categories).map((category, idx) => {
        const partnersInCategoryCount = util.countForCategory(
          partners,
          category.name
        )

        if (partnersInCategoryCount == 0) return null

        const count = util.countForCategory(partners, category.name)
        const isActive = filter == category.name
        const ariaTextWhenSelected = isActive ? "Currently Selected" : ""
        const partnerCountText = `${count} in this category`
        const ariaLabel = `Spend Partners in Category ${category.name} (${partnerCountText}) (${ariaTextWhenSelected})`

        return (
          <Theme.RewardCategory
            className="d-flex"
            idx={idx}
            active={filter == category.name}
            onClick={() => setFilter(category.name)}
            data-item-type="spend-partner-category"
            data-partners-count={partnersInCategoryCount}
            key={category.name}
            aria-label={ariaLabel}
            key={category.name}
          >
            <CategorySelectedIndicator isActive={isActive} />
            <span data-item-type="spend-partner-category-name">
              {category.name}
            </span>{" "}
            <Theme.CategoryPartnerCount active={isActive} aria-label={``}>
              {util.countForCategory(partners, category.name)}
            </Theme.CategoryPartnerCount>
          </Theme.RewardCategory>
        )
      })}
    </Theme.RewardCategories>
  )
}
RewardsFilter.propTypes = {
  categories: PropTypes.array,
  filter: PropTypes.any,
  partners: PropTypes.any,
  setFilter: PropTypes.func,
}
