import React from "react"
import { navigate } from "gatsby"
import * as util from "../templates/util"
import * as Stages from "../components/stages/index"
import * as tracking from '../tracking'

export const StageContext = React.createContext()

export const StageContextWrapper = ({ children, location }) => {
  
  const [stage, setStage] = React.useState(Stages.LOADING)
  const isMenuStage = stage => Stages.MENU_STAGES.includes(stage)

  React.useEffect(() => {
    if (util.userHasSelectedLocation()) {
      setStage(Stages.SELECT_INTEREST)
    } else {
      setStage(Stages.START_STAGE)
    }
  }, [])

  React.useEffect(() => {
    if (isMenuStage(stage)) {
      tracking.trackPageview(Stages.stageTextNames[stage]);
      navigate("/", {
        state: {
          stage: stage,
        },
      });
    }
  }, [stage])

  const nextStage = () => {
    setStage(stage => stage + 1)
    //      setStage((oldStage) => oldStage + 1)
  }

  const previousStage = () => {
    setStage(oldStage => oldStage - 1)
  }

  return (
    <StageContext.Provider
      value={{
        nextStage: nextStage,
        previousStage: previousStage,
        setStage: setStage,
        stage: stage,
      }}
    >
      {children}
    </StageContext.Provider>
  )
}
