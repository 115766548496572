import PropTypes from "prop-types";
import React from "react"
import * as Theme from "../../components/styles"
import { LocationLogo } from "./locationLogo"
import { SHOW_EVENTS, SHOW_REWARDS } from "./index"
import { Stage } from "./stage"

export const InterestSelect = ({ setStage }) => (
  <Stage name='select-interest' greeting="Your interests">
    <Theme.ChoiceContainer className="row">
      <Theme.QuestionText className="col-12">
        I want to find out about...
      </Theme.QuestionText>
      <Theme.QuestionContainer className="col-12">
        <Theme.ChoiceButton
          className="col-lg-6 col-sm-12"
          onClick={() => setStage(SHOW_REWARDS)}
          data-control-type='show-rewards'
        >
          Places I can spend points
        </Theme.ChoiceButton>
        <Theme.ChoiceButton
          className="col-lg-6 col-sm-12"
          onClick={() => setStage(SHOW_EVENTS)}
          data-control-type='show-events'
        >
          Group events
        </Theme.ChoiceButton>
      </Theme.QuestionContainer>
    </Theme.ChoiceContainer>
  </Stage>
)

InterestSelect.propTypes = {
  setStage: PropTypes.func
}
