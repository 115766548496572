import * as Theme from "../../../components/styles"
import React from "react"
import * as icons from "../modals/event/icons"
import { toolTips } from "../modals/event/tooltips"
import * as $ from 'jquery'

export const SpecialConditions = props => {

  React.useEffect(() => {

      $('[data-toggle="tooltip"]').tooltip()

  }, [])

  if (props.specialConditions == null) return null

  return (
    <>
      <Theme.SpecialConditionsList>
        {props.specialConditions.map(condition => (
          <Theme.SpecialCondition>
            <span data-toggle="tooltip" data-placement="top" title={toolTips[condition]}>
            <Theme.SpecialConditionIcon
              src={icons.conditionIcons[condition]}
              alt={"condition"}
            />
            {condition}
            </span>
          </Theme.SpecialCondition>
        ))}
      </Theme.SpecialConditionsList>
    </>
  )
}
