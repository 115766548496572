import * as Theme from "../../../components/styles";
import React from "react";
import { Tick } from "./Tick";

export const SpecialConditionsFilter = ({
  addSpecialCondition,
  removeSpecialCondition,
  filter, }) => {
  const toggleSpecialCondition = selected => condition => selected == true
    ? removeSpecialCondition(condition)
    : addSpecialCondition(condition);

  const conditions = filter.conditions.map(condition => {
    const selected = filter.specialConditions.includes(condition);
    return (
      <Theme.SpecialConditionOption
        onClick={() => toggleSpecialCondition(selected)(condition)}
        active={selected}
      >
        <Tick selected={selected} /> {condition}
      </Theme.SpecialConditionOption>
    );
  });

  return (
    <>
    <Theme.EventsFilterHeader>Filter</Theme.EventsFilterHeader>
    <Theme.SpecialConditionsSelector>
      {conditions}
    </Theme.SpecialConditionsSelector>
    </>
  );
};
