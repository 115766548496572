import React from "react"
import * as Theme from "../../../styles"

export const SpendPartnerHeader = props => {
  if (!props.picture) return null

  return (
    <Theme.SpendPartnerHeader className="col-12">
      <Theme.SpendPartnerHeaderImage
        Tag="section"
        fluid={props.picture.full}
        backgroundColor={`#fff`}
      />
    </Theme.SpendPartnerHeader>
  )
}
