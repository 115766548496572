import PropTypes from "prop-types";
import React from "react";
import * as Theme from "../../styles";
import * as Modal from "./index";
import { LargerModalContent } from "./partner/LargerModalContent";
import { SmallerModalContent } from "./partner/SmallerModalContent";
import { RewardsList } from "./partner/RewardsList";

export const PartnerModal = props => {
  return (
    <Modal.Modal {...props} label="Spend Partner Name">
      <LargerModalContent {...props} />
      <SmallerModalContent {...props} />
      <Theme.RewardsContainer className="container">
        <Theme.RewardsListHeader tabIndex={0} className="row">
          <th className="col-8">Available Rewards</th>
          <th className="col-4">Points</th>
        </Theme.RewardsListHeader>
        <RewardsList {...props} />
      </Theme.RewardsContainer>
    </Modal.Modal>
  );
};
PartnerModal.propTypes = {
  description: PropTypes.shape({
    json: PropTypes.any,
  }),
};
