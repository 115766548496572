export const INTRO_STAGE = 0
export const START_STAGE = 1
export const LOCATION_SELECT = 2
export const SHOW_REWARDS = 5
export const CORRECT_LOCATION = 6
export const INCORRECT_LOCATION = 7
export const SELECT_INTEREST = 3
export const SHOW_EVENTS = 4
export const LOADING = 8

export const isStepWithFooter = (step) => [SELECT_INTEREST, SHOW_EVENTS, SHOW_REWARDS].includes(step)

export const stageTextNames = {
  [INTRO_STAGE]: "Intro Stage",
  [START_STAGE]: "Start Stage",
  [LOCATION_SELECT]: "Location Select",
  [SHOW_REWARDS]: "Show Spend Partners",
  [SELECT_INTEREST]: "Select Interest",
  [SHOW_EVENTS]: "Show Events",
  [LOADING]: "Loading",
}

export const MENU_STAGES = [
  INTRO_STAGE,
  START_STAGE,
  LOCATION_SELECT,
  CORRECT_LOCATION,
  INCORRECT_LOCATION,
  SELECT_INTEREST,
  LOADING,
]

export { IntroStage } from "./intro"
export { StartStage } from "./start"
export { InterestSelect } from "./interest"
export { LocationSelect } from "./location"
