import React from "react"
import * as util from "../../../templates/util"
import { FILTER_IN_NEXT_MONTH } from "../index"

export const useFilter = events => {
  const availableConditions = events
    .map(event => event.node.specialConditions)
    .reduce((acc, val) => acc.concat(val), [])
    .filter(condition => condition !== null)

  const uniqueAvailableConditions = [...new Set(availableConditions)]

  const [filter, setFilter] = React.useState({
    date: FILTER_IN_NEXT_MONTH,
    specialConditions: [],
    conditions: uniqueAvailableConditions,
  })

  const updateDateFilter = newDateFilter => {
    setFilter(old => {
      return { ...old, date: newDateFilter }
    })
  }
  const removeSpecialCondition = conditionToRemove => {
    setFilter(filter => {
      return {
        ...filter,
        specialConditions: filter.specialConditions.filter(
          condition => condition !== conditionToRemove
        ),
      }
    })
  }

  const addSpecialCondition = conditionToAdd => {
    if (filter.specialConditions.includes(conditionToAdd)) return null

    setFilter(filter => {
      return {
        ...filter,
        specialConditions: filter.specialConditions.concat(conditionToAdd),
      }
    })
  }

  const filterBySpecialConditions = event => {
    if (filter.specialConditions.length == 0) return true

    if (!event.node.specialConditions) return false

    return filter.specialConditions.every(condition =>
      event.node.specialConditions.includes(condition)
    )
  }

  const filterEvents = events => {
    return events
      .filter(util.eventFilters(filter.date))
      .filter(filterBySpecialConditions)
  }

  return {
    filterEvents,
    filterBySpecialConditions,
    addSpecialCondition,
    removeSpecialCondition,
    updateDateFilter,
    filter,
    setFilter,
  }
}
