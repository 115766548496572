import React from "react"
import { Event } from "./Event"
import { NoEventsMessage } from "./NoEventsMessage"

export const ShowEvents = ({ eventsToList }) =>
  eventsToList.length == 0 ? (
    <NoEventsMessage />
  ) : (
    eventsToList.map((event, idx) => (
      <Event {...event.node} idx={idx} key={Math.random()} />
    ))
  )
