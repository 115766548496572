import React from "react"
import * as Theme from "../components/styles"
import { LocationContext } from "../contexts/location"
import * as utils from "../templates/util"
import { AnimatePresence } from "framer-motion"

export const ChangeLocationHeader = () => {
  const animationProps = {
    initial: { y: -50 },
    animate: { y: 0 },
    exit: { y: -50 },
  }
  const locationContext = React.useContext(LocationContext)

  const fullReset = () => {
    utils.unsetUserHasSeenIntro()
    locationContext.resetLocation()
  }

  if (locationContext.location == "") return null

  return (
    <AnimatePresence>
      <Theme.Header>
        <Theme.Location
          data-item-type={"location-header"}
          role="navigation"
          aria-label="Your current location"
          key={"location-header"}
        >
          <span>
            I&apos;m in{" "}
            <strong data-item-type={"location-text"}>
              {locationContext.locationMap[locationContext.location]}
            </strong>{" "}
          </span>
          <Theme.ChangeLocationButton
            onClick={() => fullReset()}
            data-control-type="change-location-link"
            aria-label="Change your current location"
          >
            [&#x27F2; change]
          </Theme.ChangeLocationButton>
        </Theme.Location>
      </Theme.Header>
    </AnimatePresence>
  )
}
