import PropTypes from "prop-types"
import React from "react"

export const Address = ({ address }) => {
  if (address == null) return null

  return (
    <p>
      {address.split(",").map(line => (
        <React.Fragment key={line}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>
  )
}
Address.propTypes = {
  address: PropTypes.string.isRequired,
}
