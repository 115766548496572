import React from "react"
import * as Theme from "../../components/styles"
import { LocationContext } from "../../contexts/location"
import { Stage } from "./stage"

const RegionSelectList = ({ setRegion, region }) => {
  const locationContext = React.useContext(LocationContext)

  return (<Theme.LocationSelectInput
    data-control-type="region-select"
    id="region"
    aria-label="My Region"
    onChange={e => {
      setRegion(e.target.value)
    }}
    value={region}
  >
    <option value=""> </option>
    {Object.keys(locationContext.regions.regions).map(region => {
      return (
        <option value={locationContext.regions[region]} key={locationContext.regions[region]}>{region}</option>
      )
    })}
  </Theme.LocationSelectInput>)

}

const LocationSelectList = ({ setSelected, region }) => {
  const locationContext = React.useContext(LocationContext)

  const filterByRegion = (locations) => {
    const e = Object.fromEntries(Object.entries(locations).filter(([slug, name]) => {
      return locationContext.regions.serviceRegions[slug] == locationContext.regions.regions[region];
    }))
    return e
  }

  const locationSelectOptions = f => {
    return Object.keys(filterByRegion(locationContext.locationMap)).sort().map(f)
  }

  return (
    <Theme.LocationSelectInput
      data-control-type="location-select"
      id="location"
      aria-label="My Location"
      onChange={e => {
        locationContext.setLocation(e.target.value)
        setSelected(true)
      }}
      value={locationContext.location}
    >
      <option value=""> </option>
      {locationSelectOptions(locationName => {
        return (
          <option value={locationName} key={locationName}>
            {locationContext.locationMap[locationName]}
          </option>
        )
      })}
    </Theme.LocationSelectInput>
  )
}

export const SelectLocation = ({ nextStage, setUserLocation, location }) => {
  const [isSelected, setSelected] = React.useState(false)
  const [region, setRegion] = React.useState(null)
  const locationContext = React.useContext(LocationContext)

  const RegionSection = () => (<Theme.RegionSelect isRegionSet={(region !== null)}>
    <Theme.QuestionText for="location" id="location-label">
      <Theme.BolderText>Please select your location in the UK</Theme.BolderText>
      This will help us find your local WDP service
    </Theme.QuestionText>
    <RegionSelectList region={region} setRegion={setRegion} />
  </Theme.RegionSelect>)

  const LocationSection = () => (<>
    <Theme.Divider>&nbsp;</Theme.Divider>
    <Theme.QuestionText for="location" id="location-label">
      <Theme.BolderText>What is your local WDP service?</Theme.BolderText>
      Please select your location
    </Theme.QuestionText>
    <LocationSelectList
      setSelected={setSelected}
      region={region}
    />
    <Theme.NavButton
      disabled={!isSelected || (locationContext.location == "")}
      onClick={() => (isSelected ? nextStage() : null)}
      data-control-type="next-button"
      aria-label="Go to next step"
    >
      Next &#x27A1;
    </Theme.NavButton>
  </>)

  return (
    <Stage name={"location"} greeting={"Your location"}>
      <Theme.ChoiceContainer className="col-12">
        <RegionSection />
        {(region) ? <LocationSection /> : null}
      </Theme.ChoiceContainer>
    </Stage>
  )
}

export const LocationSelect = ({ nextStage }) => {
  const [location, setUserLocation] = React.useState("")

  return (<>
    <SelectLocation 
      nextStage={nextStage} 
      setUserLocation={setUserLocation} 
      location={location}
      />
    </>)
}
