import * as Theme from "../../../components/styles";
import React from "react";

export const EventsPoints = ({ pointsRequired, arePointsRequired }) => {

  if (arePointsRequired !== true)
    return <Theme.EventsPoints>FREE</Theme.EventsPoints>;

  return <Theme.EventsPoints><strong>{pointsRequired}</strong> POINTS</Theme.EventsPoints>;
};
