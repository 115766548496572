import PropTypes from "prop-types";
import React from "react";
import * as Theme from "../../styles";
import { generateModalIDTag } from "../modals";


export const Modal = ({ name, children, idx }) => {
  return (
    <div
      className="modal fade margin-0"
      id={"modal-" + idx}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={generateModalIDTag(idx, "title")}
      data-focus="true"
      aria-hidden="true"
      data-backdrop={false}
      data-keyboard="false"
      data-item-type="spend-partner-modal"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <h2
              className="modal-title"
              id={generateModalIDTag(idx, "title", "#")}
              tabIndex={0}
            >
              {name}
            </h2>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Theme.PartnerModalBody class="modal-body">
            {children}
          </Theme.PartnerModalBody>
          <div className="modal-footer">
            <Theme.CloseButton
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </Theme.CloseButton>
          </div>
        </div>
      </div>
    </div>
  );
};
Modal.propTypes = {
  children: PropTypes.any.isRequired,
  idx: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};
