import * as Theme from "../../../components/styles";
import React from "react";
import Img from 'gatsby-image'

export const SpendPartner = props => {
  if (!props.logo)
    return null;

    console.log(props)

  return (
    <Theme.SpendPartnerLogo aria-hidden={true}>
      <Img fixed={props.logo.large} alt={props.name + ` Logo`} />
    </Theme.SpendPartnerLogo>
  );
};
