import * as Theme from "../../../components/styles"
import React from "react"
import {
  FILTER_IN_NEXT_MONTH,
  FILTER_IN_NEXT_SIX_MONTHS,
  FILTER_IN_NEXT_WEEK,
} from "../index"
import { SpecialConditionsFilter } from "./SpecialConditionsFilter"
import { Tick } from "./Tick"
import * as tracking from '../../../tracking';

export const EventsFilter = props => {

  const trackFilter = (filter, name) => {
    props.updateDateFilter(filter);
    tracking.trackEventFilter(name);
  }

  const isSelected = filter => props.filter.date === filter

  return (
    <Theme.EventsFilter className="col-sm-12 col-lg-3" role="search" aria-describedby="events-filter-header">
      <Theme.EventsFilterHeader id="events-filter-header">When?</Theme.EventsFilterHeader>
      <Theme.FilterButton
        data-control-type="filter"
        data-filter-value="In Next Week"
        active={isSelected(FILTER_IN_NEXT_WEEK)}
        onClick={() => trackFilter(FILTER_IN_NEXT_WEEK, "Next Week")}
      >
        <Tick selected={isSelected(FILTER_IN_NEXT_WEEK)} />
        In Next Week
      </Theme.FilterButton>
      <Theme.FilterButton
        data-control-type="filter"
        data-filter-value="In Next Month"
        active={isSelected(FILTER_IN_NEXT_MONTH)}
        onClick={() => trackFilter(FILTER_IN_NEXT_MONTH, "Next Month")}
      >
        <Tick selected={isSelected(FILTER_IN_NEXT_MONTH)} />
        In Next Month
      </Theme.FilterButton>
      <Theme.FilterButton
        data-control-type="filter"
        data-filter-value="In Next 6 Months"
        active={isSelected(FILTER_IN_NEXT_SIX_MONTHS)}
        onClick={() => trackFilter(FILTER_IN_NEXT_SIX_MONTHS, "Next 6 Months")}
      >
        <Tick selected={isSelected(FILTER_IN_NEXT_SIX_MONTHS)} />
        In Next 6 Months
      </Theme.FilterButton>
      <SpecialConditionsFilter {...props} />
    </Theme.EventsFilter>
  )
}
