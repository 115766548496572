import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../../components/styles"
import { PartnerModal } from "../modals/PartnerModal"
import Img from "gatsby-image"
import * as tracking from "../../../tracking"

const partnerStatusAsString = (currentlyOpen) => currentlyOpen === true ? "open" : "closed"

const PartnerClosedIndicator = ({ closed }) => {
  if (!closed) return null

  return <span></span>
}

export const Partner = props => {
  const trackPartnerClick = () => tracking.trackSpendPartnerView(props.name)

  return (
    <Theme.PartnerCard
      data-toggle="modal"
      data-target={`#modal-${props.idx}`}
      data-item-type={"spend-partner-list-item"}
      data-partner-name={props.name}
      data-partner-status={partnerStatusAsString(props.currentlyOpen)}
      onClick={trackPartnerClick}
      role="listitem"
      tabIndex={0}
      label="Spend Partner"
      isOpen={props.currentlyOpen}
    >
      <Theme.PartnerLogoSquare isOpen={props.currentlyOpen}>
        <Img
          fixed={props.logo.fixed}
          alt={props.name + " Logo"}
          role="presentation"
        />
      </Theme.PartnerLogoSquare>
      <Theme.PartnerDescription isOpen={props.currentlyOpen}>
        <Theme.PartnerCardTitle class="card-title" href="#">
          {props.name}
        </Theme.PartnerCardTitle>
        <Theme.PartnerCategories>
          {props.categories.map(category => (
            <Theme.PartnerCategory
              key={category.name}
              aria-label={`Filed as ${category.name}`}
            >
              {category.name}{" "}
              <PartnerClosedIndicator closed={!props.currentlyOpen} />
            </Theme.PartnerCategory>
          ))}
        </Theme.PartnerCategories>
      </Theme.PartnerDescription>
      <PartnerModal {...props} idx={props.idx} />
    </Theme.PartnerCard>
  )
}

Partner.propTypes = {
  categories: PropTypes.array.isRequired,
  idx: PropTypes.number,
  logo: PropTypes.shape({
    fixed: PropTypes.any,
  }),
  name: PropTypes.string.isRequired,
}
