import PropTypes from "prop-types"
import React from "react"
import { RewardItem } from "./RewardItem"

export const RewardsList = props => {
  /*
    Spend Partner reward points aren't sorted by their numeric value by default, sorting happens here.
  */

  const rewardsSortedBySpendPartner = React.useMemo(() => {
    return props.rewards.sort((r1, r2) => (r1.pointsRequired - r2.pointsRequired))
  })

  return (
    <React.Fragment>
      {rewardsSortedBySpendPartner.map((reward, idx) => (
        <RewardItem key={reward.name} {...reward} idx={idx} />
      ))}
    </React.Fragment>
  )
}
RewardsList.propTypes = {
  rewards: PropTypes.isRequired,
}
