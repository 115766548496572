import PropTypes from "prop-types"
import React from "react"
import * as Theme from "../../../styles"

export const RewardItem = props => {
  return (
    <Theme.PointsRow
      className="row"
      data-item-type="partner-rewards-item"
      tabIndex={0}
    >
      <Theme.PointsDescription
        className="col-8"
        data-item-type="partner-rewards-item-description"
      >
        <Theme.RewardName>{props.description}</Theme.RewardName>
        <Theme.RewardFurtherDescription>
          {props.furtherDescription}
        </Theme.RewardFurtherDescription>
      </Theme.PointsDescription>
      <Theme.PointsRequired
        className="col-4"
        data-item-type="partner-rewards-item-points"
      >
        <Theme.PointsRequiredText>
          {props.pointsRequired} pts
        </Theme.PointsRequiredText>
      </Theme.PointsRequired>
    </Theme.PointsRow>
  )
}
RewardItem.propTypes = {
  description: PropTypes.string,
  pointsRequired: PropTypes.bool,
}
