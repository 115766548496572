import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as Theme from "../../../styles";
import Img from "gatsby-image";
import { SpendPartnerLink } from "./SpendPartnerLink";
import { ClosedNotice } from './ClosedNotice'

const partnerTextContent = (description) => description !== undefined ? documentToReactComponents(JSON.parse(description.raw)) : null;

export const LargerModalContent = props => {

  return (<div className="d-none d-lg-block">
    <div className="row">
      <div className="col-6">
        <Theme.SpendPartnerContent className="col-lg-12 col-sm-12" tabIndex={0}>
          <ClosedNotice closed={!props.currentlyOpen} />
          {partnerTextContent(props.description)}
        </Theme.SpendPartnerContent>
      </div>
      <Theme.SpendPartnerContentLarge className="col-6 d-flex flex-column align-items-center padding-1">
        {props.picture && (
          <Img
            Tag="section"
            fixed={props.picture.fixed}
            backgroundColor={`#fff`} />
        )}
        {props.websiteUrl && <SpendPartnerLink url={props.websiteUrl} />}
      </Theme.SpendPartnerContentLarge>
    </div>
  </div>)
};
